import React from 'react'
import styles from '../styles/pages/Home.module.css';
import MyServices from '../components/MyServices';
import Contact from '../components/Contact';
import demarsOntime from '../images/demars_ontime.png';
import ivElectrolyte from '../images/iv_electrolyte.png';
import SimpleNav from '../components/SimpleNav';

const Home = () => {
    return (
        <div className={styles.container}>
            <div className={styles.banner}>
                <SimpleNav />

                <div style={{maxWidth: 700}}>
                    <h1>👋 Hello, I'm Enock</h1>
                    <h3>🚀 I Build Digital Solutions.</h3>
                    <h2>🌐 Full-Stack Software Developer.</h2>
                    <h4>🔍 Open to Opportunities.</h4>
                    <h4>Let's Create Something Amazing Together!</h4>
                </div><br />

                <a href="/#portfolio" className={styles.explore_btn} style={{color: 'black', textDecoration: 'none'}}>View Projects</a>
            </div>

            <div style={{paddingTop: 30}} id="about">
                <h2 style={{textAlign: 'center', marginTop: 25}}>ABOUT ME</h2>
                <p style={{margin: 20, textAlign: 'center'}}>👨‍💻 Full-Stack Developer | 🛠️ DevOps Enthusiast | 🏛️ Software Architect</p>

                <p style={{margin: 20, textAlign: 'center'}}>Hey there! I'm a seasoned full-stack software developer with over 5 years of hands-on experience. I have a passion for crafting seamless digital experiences, from front-end finesse to back-end expertise.</p>

                <p style={{margin: 20, textAlign: 'center'}}>🚀 I don't stop at just writing code; I architect it! I believe in crafting robust, scalable systems that stand the test of time. With a side of DevOps expertise, I ensure our creations are deployed smoothly and run like a well-oiled machine.</p>

                <p style={{margin: 20, textAlign: 'center'}}>I'm not just seeking opportunities; I'm seeking challenges, collaborations, and the chance to turn visions into reality. Let's connect and build something extraordinary!</p>
            </div>

            <div id="portfolio" style={{paddingTop: 20}}>
                <MyServices 
                    title='MY PORTFOLIO'
                    projects={[
                        {image: demarsOntime, project_name: 'Demars Ontime Transportation', source_code: '/#', preview: '/#'},
                        {image: ivElectrolyte, project_name: 'IV Lounge Website', source_code: '/#', preview: '/#'},
                        {image: demarsOntime, project_name: 'Demars Ontime Transportation', source_code: '/#', preview: '/#'},
                        {image: ivElectrolyte, project_name: 'IV Lounge Website', source_code: '/#', preview: '/#'},
                        {image: demarsOntime, project_name: 'Demars Ontime Transportation', source_code: '/#', preview: '/#'},
                        {image: ivElectrolyte, project_name: 'IV Lounge Website', source_code: '/#', preview: '/#'}
                    ]}
                />
            </div>

            <div id="contact" style={{paddingTop: 30}}>
                <Contact
                    title="Get in touch"
                />
            </div>
        </div>  
    )
}

export default Home