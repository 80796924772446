import { Paper } from '@mui/material';
import styles from '../styles/components/Contact.module.css';
import CustomtextInput from './CustomTextInput';
import PersonIcon from '@mui/icons-material/Person';
import CustomTextArea from './CustomTextArea';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import EmailIcon from '@mui/icons-material/Email';
import { useState } from 'react';

interface ContactProps{
    title: string
}

const Contact = ({title} : ContactProps) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const nameProps = {
        placeholder: "Name",
        type: "text",
        value: name,
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)
    }

    const emailProps = {
        placeholder: "Email",
        type: "email",
        value: email,
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)
    }

    const messageProps = {
        placeholder: "Message",
        rows: 5,
        value: message,
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => setMessage(e.target.value)
    }

    return (
        <div className={styles.contact}>
            <h4>{title}</h4>

            <Paper className={styles.paper}>
                <CustomtextInput 
                    Icon={<PersonIcon />}
                    {...nameProps}
                /><br />

                <CustomtextInput 
                    Icon={<AlternateEmailIcon />}
                    {...emailProps}
                /><br />

                <CustomTextArea
                    Icon={<EmailIcon />}
                    {...messageProps}
                /><br />

                <button className={styles.send_btn}>Send Message</button>
            </Paper>
        </div>
    )
}

export default Contact;