import { Link } from 'react-router-dom';
import styles from '../styles/components/SimpleNav.module.css';

const SimpleNav = () => {
    return (
        <div className={styles.nav}>
            <div className={styles.content}>
                <a href="/" className={styles.link}>Home</a>
                <a href="/#about" className={styles.link}>About</a>
                <a href="/#portfolio" className={styles.link}>Portfolio</a>
                <a href="/#contact" className={styles.link}>Contact</a>
            </div>
        </div>
    )
}

export default SimpleNav;