import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { Container } from '@mui/material';
import Home from './pages/Home';
import Layout from './components/Layout';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />
  },
]);

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Container style={{maxWidth: 1500}}>
        <Layout>
          <RouterProvider router={router} />
        </Layout>
      </Container>
    </ThemeProvider>
  );
}

export default App;
