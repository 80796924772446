import styles from '../styles/components/SimpleFooter.module.css';

const SimpleFooter = () => {
    return (
        <div className={styles.footer}>
            <p>&copy; 2023 All Rights Reserved.</p>
        </div>
    )
}

export default SimpleFooter;