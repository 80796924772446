import React from 'react';
import styles from '../styles/components/InputFields.module.css';

interface InputProps{
    Icon: React.ReactNode,
}

const CustomtextInput : React.FC<InputProps> = ({Icon, ...props} : InputProps) => {
    return (
        <div className={styles.text_input}>
            <div className={styles.icon_container}>
                {Icon}
            </div>

            <input {...props} />
        </div>
    )
}
export default CustomtextInput;