import { Box, Divider, Paper, Tooltip, Typography } from '@mui/material';
import styles from '../styles/components/Layout.module.css';
import profilePicture from '../images/profile_picture.jpg';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import CircularProgress, {CircularProgressProps} from '@mui/material/CircularProgress';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import EmailIcon from '@mui/icons-material/Email';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import SimpleFooter from './SimpleFooter';

export interface LayoutProps  { 
    children: React.ReactNode
}

function CircularProgressWithLabel(
    props: CircularProgressProps & { value: number },
  ) {
    return (
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress variant="determinate" {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="caption"
            component="div"
            color="text.secondary"
          >{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
    );
  }

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 5,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? 'yellow' : 'yellow',
    },
  }));

const Layout = ({children} : LayoutProps) => {
    const [development_progress, setDevelopmentProgress] = useState(10);
    const [devops_progress, setDevopsProgress] = useState(10);
    const [architect_progress, setArchitectProgress] = useState(10);

    const [laravel_progress, setLaravelProgress] = useState(10);
    const [core_php_progress, setCorePHPProgress] = useState(10);
    const [elixir_progress, setElixirProgress] = useState(10);
    const [react_progress, setReactProgress] = useState(10);
    const [react_native_progress, setReactNativeProgress] = useState(10);
    const [vanilla_js_progress, setVanillaJsProgress] = useState(10);
    const [typescript_progress, setTypescriptProgress] = useState(10);
    const [mysql_progress, setMysqlProgress] = useState(10);
    const [postgress_progress, setPostGresProgress] = useState(10);
    const [mongodb_progress, setMongoDbProgress] = useState(10);

    const [status, setStatus] = useState('loading');
    const [status_progress, setStatusProgress] = useState(10);

    useEffect(() => {
        const timer = setInterval(() => {
            setStatusProgress((prevProgress) => (prevProgress < 100 ? prevProgress + 10 : 100));
        }, 100);

        setTimeout(() => {
            setStatus('success');
        }, 4000);

        return () => {
            clearInterval(timer);
        };
    }, []);

    useEffect(() => {
        if(status === 'success'){
            const timer = setInterval(() => {
                setDevelopmentProgress((prevProgress) => (prevProgress < 90 ? prevProgress + 10 : 95));
                setDevopsProgress((prevProgress) => (prevProgress < 75 ? prevProgress + 10 : 75));
                setArchitectProgress((prevProgress) => (prevProgress < 70 ? prevProgress + 10 : 70));

                // Linear progress
                setLaravelProgress((prevProgress) => (prevProgress < 95 ? prevProgress + 10 : 95));
                setCorePHPProgress((prevProgress) => (prevProgress < 90 ? prevProgress + 10 : 90));
                setElixirProgress((prevProgress) => (prevProgress < 75 ? prevProgress + 10 : 75));
                setReactProgress((prevProgress) => (prevProgress < 90 ? prevProgress + 10 : 90));
                setReactNativeProgress((prevProgress) => (prevProgress < 90 ? prevProgress + 10 : 90));
                setVanillaJsProgress((prevProgress) => (prevProgress < 85 ? prevProgress + 10 : 85));
                setTypescriptProgress((prevProgress) => (prevProgress < 85 ? prevProgress + 10 : 85));
                setMysqlProgress((prevProgress) => (prevProgress < 90 ? prevProgress + 10 : 90));
                setPostGresProgress((prevProgress) => (prevProgress < 85 ? prevProgress + 10 : 85));
                setMongoDbProgress((prevProgress) => (prevProgress < 90 ? prevProgress + 10 : 90));
            }, 100);

            return () => {
                clearInterval(timer);
            };
        }
    }, [status]);

    return (
        <div className={styles.container}>
            {status === 'success' && (
                <>
                    <Paper className={styles.left_side_bar}>
                        <div className={styles.top_part}>
                            <div className={styles.image_container}>
                                <img src={profilePicture} className={styles.profile_picture} alt='' />
                                <FiberManualRecordIcon style={{position: 'absolute', bottom: 15, right: 0, color: 'yellow'}} />
                            </div>

                            <h4>Enock D Soko</h4>
                            <p>Full-Stack Developer</p>
                        </div>
                        
                        <div className={styles.skills_summary}>
                            <div className={styles.skill_circular_container}>
                                <CircularProgressWithLabel value={development_progress} style={{color: 'yellow'}} />
                                <p>Development</p>
                            </div>
                            <div className={styles.skill_circular_container}>
                                <CircularProgressWithLabel value={devops_progress} style={{color: 'yellow'}} />
                                <p>DevOps</p>
                            </div>
                            <div className={styles.skill_circular_container}>
                                <CircularProgressWithLabel value={architect_progress} style={{color: 'yellow'}} />
                                <p>Architect</p>
                            </div>
                        </div>
                        <Divider />
                        
                        <div className={styles.linear_skills}>
                            <div style={{marginTop: 10}}>
                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                    <p style={{margin: 0, padding: 0, width: '100%'}}>Laravel</p>
                                </div>
                                <BorderLinearProgress variant="determinate" value={laravel_progress} />
                            </div>

                            <div style={{marginTop: 20}}>
                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                    <p style={{margin: 0, padding: 0, width: '100%'}}>Core PHP</p>
                                </div>
                                <BorderLinearProgress variant="determinate" value={core_php_progress} />
                            </div>

                            <div style={{marginTop: 20}}>
                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                    <p style={{margin: 0, padding: 0, width: '100%'}}>Elixir</p>
                                </div>
                                <BorderLinearProgress variant="determinate" value={elixir_progress} />
                            </div>

                            <div style={{marginTop: 20}}>
                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                    <p style={{margin: 0, padding: 0, width: '100%'}}>React</p>
                                </div>
                                <BorderLinearProgress variant="determinate" value={react_progress} />
                            </div>

                            <div style={{marginTop: 20}}>
                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                    <p style={{margin: 0, padding: 0, width: '100%'}}>React Native</p>
                                </div>
                                <BorderLinearProgress variant="determinate" value={react_native_progress} />
                            </div>

                            <div style={{marginTop: 20}}>
                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                    <p style={{margin: 0, padding: 0, width: '100%'}}>Vanilla Javascript</p>
                                </div>
                                <BorderLinearProgress variant="determinate" value={vanilla_js_progress} />
                            </div>

                            <div style={{marginTop: 20}}>
                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                    <p style={{margin: 0, padding: 0, width: '100%'}}>Typescript</p>
                                </div>
                                <BorderLinearProgress variant="determinate" value={typescript_progress} />
                            </div>

                            <div style={{marginTop: 20}}>
                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                    <p style={{margin: 0, padding: 0, width: '100%'}}>Mysql</p>
                                </div>
                                <BorderLinearProgress variant="determinate" value={mysql_progress} />
                            </div>

                            <div style={{marginTop: 20}}>
                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                    <p style={{margin: 0, padding: 0, width: '100%'}}>Postgress</p>
                                </div>
                                <BorderLinearProgress variant="determinate" value={postgress_progress} />
                            </div>

                            <div style={{marginTop: 20}}>
                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                    <p style={{margin: 0, padding: 0, width: '100%'}}>MongoDb</p>
                                </div>
                                <BorderLinearProgress variant="determinate" value={mongodb_progress} />
                            </div>
                        </div>

                        <div className={styles.sidebar_footer}>
                            <a href="https://www.linkedin.com/in/enock-soko-08b87a182/" target='_blank' rel="noreferrer" style={{color: 'white'}}>
                                <Tooltip title="Linkedin">
                                    <LinkedInIcon />
                                </Tooltip>
                            </a>

                            <a href="https://github.com/Enoch18" target='_blank' rel="noreferrer" style={{color: 'white', marginLeft: 15}}>
                                <Tooltip title="Github">
                                    <GitHubIcon />
                                </Tooltip>
                            </a>

                            <a href="mailto:sokoenock@gmail.com" target='_blank' rel="noreferrer" style={{color: 'white', marginLeft: 15}}>
                                <Tooltip title="Email">
                                    <EmailIcon />
                                </Tooltip>
                            </a>
                        </div>
                    </Paper>

                    <Paper className={styles.content}>
                        {children}

                        <SimpleFooter />
                    </Paper>
                </>
            )}

            {status === 'loading' && (
                <div
                    style={{
                        width: '100%',
                        height: '100vh',
                        paddingTop: '30%',
                        textAlign: 'center'
                    }}
                >
                    <p>Enock D Soko</p>
                    <BorderLinearProgress variant="determinate" value={status_progress} style={{width: 300, marginLeft: 'auto', marginRight: 'auto'}} />
                    <p>{status_progress}%</p>
                </div>
            )}
        </div>
    )
}

export default Layout;