import React from 'react';
import styles from '../styles/components/InputFields.module.css';

interface InputProps{
    Icon: React.ReactNode,
}

const CustomTextArea : React.FC<InputProps> = ({Icon, ...props} : InputProps) => {
    return (
        <div className={styles.text_input}>
            <div className={styles.icon_container} style={{alignItems: 'flex-start', paddingTop: 10}}>
                {Icon}
            </div>

            <textarea {...props}></textarea>
        </div>
    )
}
export default CustomTextArea;