import { Grid, Paper } from '@mui/material';
import styles from '../styles/components/Services.module.css';
import VisibilityIcon from '@mui/icons-material/Visibility';
import GitHubIcon from '@mui/icons-material/GitHub';

interface ServicesProps {
    title: string,
    projects: {
        image: string, 
        preview: string, 
        project_name: string,
        source_code: string
    }[]
}

const MyServices = ({title, projects} : ServicesProps) => {
    return (
        <div className={styles.services}>
                <h2 style={{textAlign: 'center', marginTop: 25}}>{title}</h2>
                
                <Grid container spacing={2}>
                    {projects.map((project, index) => (
                        <Grid item xs={12} sm={12} md={4} key={index}>
                            <Paper className={styles.paper}>
                                <img src={project.image} alt="" style={{width: '100%'}} />
                                <p><b>{project.project_name}</b></p>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row'
                                    }}
                                >
                                    <div style={{display: 'flex', flex: 1}}>
                                        <a href="/#" style={{color: 'white', display: 'flex', flex: 1, alignItems: 'center'}}>
                                            <VisibilityIcon />
                                            <h4 style={{marginLeft: 5}}>Live Preview</h4>
                                        </a>
                                    </div>

                                    <div style={{display: 'flex', flex: 1}}>
                                        <a href="/#" style={{color: 'white', display: 'flex', flex: 1, alignItems: 'center'}}>
                                            <GitHubIcon />
                                            <h4 style={{marginLeft: 5}}>Source Code</h4>
                                        </a>
                                    </div>
                                </div>
                            </Paper>
                        </Grid>
                     ))}
                </Grid>
            </div>
    )
}

export default MyServices;